import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import IPhoneBookCategoryDto from "../models/IPhoneBookCategory.dto";
import {
  getPhoneBookCategories,
  createPhoneBookEntry,
  getPhoneBookEntries,
  getPhoneBookEntry,
  getImageForEntry,
} from "../services/Http.service";

import { Storage } from "@capacitor/storage";
import moment from "moment";

class PhoneBookStore {
  categories: IPhoneBookCategoryDto[] = [];
  selectedCategory: any;
  phoneBookEntriesForCategory: any[] = [];
  phoneBookSearchEntries: any = [];
  selectedPhoneBookEntry: any = {};
  childEntry: any = {};
  query = "";
  locationQuery = "all";
  phoneBookEntries: any[] = [];
  imgURL: string = "";
  childImgURL: string = "";

  constructor() {
    makeObservable(this, {
      imgURL: observable,
      childImgURL: observable,
      query: observable,
      phoneBookEntries: observable,
      locationQuery: observable,
      categories: observable,
      childEntry: observable,
      selectedPhoneBookEntry: observable,
      selectedCategory: observable,
      phoneBookEntriesForCategory: observable,
      phoneBookSearchEntries: observable,
      loadCategories: action,
      loadPhoneBookEntries: action,
      getPhoneBookEntry: action,
      getCategory: action,
      loadPhoneBookForCategory: action,
      getImg: action,
      filteredEntries: computed,
      searchResults: computed,
      searchResultsForCategory: computed,
      filteredCategoryEntries: computed,
      getChildEntry: action,
      getImgChild: action,
    });

    this.load();
  }

  load = async () => {
    let data: any;
    try {
      data = await Storage.get({
        key: "phoneBook",
      });
    } catch (error) {
      console.log(error);
    }

    if (data && data.value) {
      runInAction(() => {
        this.categories = JSON.parse(data.value as string);
      });
    }
  };

  loadPhoneBookEntries = async () => {
    let tmp: any[] = [];
    const response = await getPhoneBookEntries();
    if (response.status === 200) {
      tmp = response.data.data;
    }

    try {
      await this.setStorage(JSON.stringify(tmp), "phoneBookEntries");
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.phoneBookEntries = tmp;
    });
  };

  get searchResults() {
    let filteredResults: any[] = [];
    let searchResults: any[] = [];

    if (!this.phoneBookEntries || this.query === "") {
      return [];
    }

    filteredResults[0] = this.phoneBookEntries;
    const searchWords = this.query.trim().toLowerCase().split(" ");

    for (let i = 0; i < searchWords.length; i++) {
      let searchword = searchWords[i];

      filteredResults.length === i + 1 && filteredResults.push([]);

      for (const entry of filteredResults[i]) {
        if (i === 0 && !entry.attributes?.suche) {
          continue;
        }

        if (
          i === 0 &&
          this.locationQuery !== "all" &&
          this.locationQuery !== entry.attributes?.location?.toLowerCase()
        ) {
          continue;
        }

        const name = entry.attributes?.name?.trim().toLowerCase() || "";
        const nameArr = name.split(" ") || [];
        const nameA = entry.attributes?.nameA?.trim().toLowerCase() || "";
        const responsiblePerson =
          entry.attributes?.responsiblePerson?.trim().toLowerCase() || "";
        const responsiblePersonArr = responsiblePerson.split(" ") || [];
        const phoneNumber = entry.attributes?.phoneNumber?.replace(/ /g, "");
        const mobileNumber = entry.attributes?.mobileNumber?.replace(/ /g, "");
        //if (name && name?.startsWith(searchword)) {
        if (name && nameArr?.some((str: string) => { return str?.startsWith(searchword) })) {
          if (i === 0) {
            //name.length === searchword.length
            nameArr?.some((str: string) => { return str?.length === searchword?.length })
              ? (entry["prio"] = 6)
              : (entry["prio"] = 5);
          }
          filteredResults[i + 1].push(entry);
        } else if (nameA && nameA.startsWith(searchword)) {
          if (i === 0) {
            nameA.length === searchword.length
              ? (entry["prio"] = 5)
              : (entry["prio"] = 3);
          }
          filteredResults[i + 1].push(entry);
        }
        else if (
          responsiblePersonArr.some((str: string) => {
            return str.startsWith(searchword);
          })
        ) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        }
        else if (phoneNumber && phoneNumber.includes(searchword)) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        } else if (mobileNumber && mobileNumber.includes(searchword)) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        }
      }
    }

    searchResults = filteredResults[filteredResults.length - 1];
    const { priv, loc } = searchResults.reduce(
      (acc, entry) => {
        if (
          entry.attributes?.categories?.data?.length === 0 &&
          !entry.attributes?.responsiblePerson
        ) {
          acc.priv.push(entry);
        } else {
          acc.loc.push(entry);
        }
        return acc;
      },
      { priv: [], loc: [] }
    );
    priv.sort((a: any, b: any) => {
      // First, sort by prio (ascending order)
      if (a.prio !== b.prio) {
        return b.prio - a.prio;
      }
      const aFirstName = a.attributes?.name || "";
      const bFirstName = b.attributes?.name || "";
      const firstNameComparison = aFirstName?.localeCompare(bFirstName);
      const aLastName = a.attributes?.nameA || "";
      const bLastName = b.attributes?.nameA || "";
      if (firstNameComparison === 0) {
        return aLastName?.localeCompare(bLastName);
      }

      return firstNameComparison;
    });
    loc.sort((a: any, b: any) => {
      // First, sort by prio (ascending order)
      if (a.prio !== b.prio) {
        return b.prio - a.prio;
      }
      // Handle undefined for firstname - treat undefined as lower value
      const aFirstName = a.attributes?.name || "";
      const bFirstName = b.attributes?.name || "";
      const firstNameComparison = aFirstName?.localeCompare(bFirstName);
      // If firstname is the same, sort by lastname, handling undefined
      const aLastName = a.attributes?.nameA || "";
      const bLastName = b.attributes?.nameA || "";
      if (firstNameComparison === 0) {
        return aLastName?.localeCompare(bLastName);
      }
      return firstNameComparison;
    });
    // Step 1: Sort both arrays from high to low by score (to ensure order)
    priv.sort((a: any, b: any) => b.prio - a.prio);
    loc.sort((a: any, b: any) => b.prio - a.prio);
    // Step 2: Split arrays into two parts (prio > 5 and prio ≤ 5)
    const priv_high = priv.filter((item: any) => item.prio > 4);
    const priv_low = priv.filter((item: any) => item.prio <= 4);
    const arr2_high = loc.filter((item: any) => item.prio > 4);
    const arr2_low = loc.filter((item: any) => item.prio <= 4);
    // Step 3: Merge in the required order
    return [...priv_high, ...arr2_high, ...priv_low, ...arr2_low];
  }

  get searchResultsForCategory() {
    let filteredResults: any[] = [];
    let searchResults: any[] = [];
    let category = this.selectedCategory;

    if (!this.phoneBookEntries || this.query === "") {
      return [];
    }

    filteredResults[0] = this.phoneBookEntries;
    const searchWords = this.query.trim().toLowerCase().split(" ");

    for (let i = 0; i < searchWords.length; i++) {
      let searchword = searchWords[i];

      filteredResults.length === i + 1 && filteredResults.push([]);
      for (const entry of filteredResults[i]) {
        if (i === 0 && !entry.attributes?.suche) {
          continue;
        }

        if (
          i === 0 &&
          this.locationQuery !== "all" &&
          this.locationQuery !== entry.attributes?.location?.toLowerCase()
        ) {
          continue;
        }
        if (
          i === 0 &&
          !entry?.attributes?.categories?.data?.find(
            (element: any) => element.id === category.id
          )
        ) {
          continue;
        }
        const name = entry.attributes?.name?.trim().toLowerCase() || "";
        const nameArr = name?.split(" ") || [];
        const nameA = entry.attributes?.nameA?.trim().toLowerCase() || "";
        const responsiblePerson =
          entry.attributes?.responsiblePerson?.trim().toLowerCase() || "";
        const responsiblePersonArr = responsiblePerson.split(" ") || [];
        const phoneNumber = entry.attributes?.phoneNumber?.replace(/ /g, "");
        const mobileNumber = entry.attributes?.mobileNumber?.replace(/ /g, "");
        //if (name && name?.startsWith(searchword)) {
        if (name && nameArr?.some((str: string) => { return str?.startsWith(searchword) })) {
          if (i === 0) {
            nameArr?.some((str: string) => { return str?.length === searchword?.length })
              ? (entry["prio"] = 6)
              : (entry["prio"] = 5);
          }
          filteredResults[i + 1].push(entry)
        } else if (nameA && nameA.startsWith(searchword)) {
          if (i === 0) {
            nameA.length === searchword.length
              ? (entry["prio"] = 4)
              : (entry["prio"] = 3);
          }
          filteredResults[i + 1].push(entry);
        } else if (
          responsiblePersonArr.some((str: string) => {
            return str.startsWith(searchword);
          })
        ) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        } else if (phoneNumber && phoneNumber.includes(searchword)) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        } else if (mobileNumber && mobileNumber.includes(searchword)) {
          if (i === 0) {
            entry["prio"] = 2;
          }
          filteredResults[i + 1].push(entry);
        }
      }
    }

    searchResults = filteredResults[filteredResults.length - 1];
    searchResults.sort((a: any, b: any) => {
      // First, sort by prio (ascending order)
      if (a.prio !== b.prio) {
        return b.prio - a.prio;
      }
      const aFirstName = a.attributes?.name || "";
      const bFirstName = b.attributes?.name || "";
      const firstNameComparison = aFirstName?.localeCompare(bFirstName);
      const aLastName = a.attributes?.nameA || "";
      const bLastName = b.attributes?.nameA || "";
      if (firstNameComparison === 0) {
        return aLastName?.localeCompare(bLastName);
      }

      return firstNameComparison;
    });
    return searchResults;
  }

  async setStorage(data: string, key: string) {
    try {
      await Storage.set({
        key: key,
        value: data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadCategories(force = false) {
    if (this.categories && this.categories.length > 0 && !force) {
      return;
    }

    const tmp: any = [];
    const response = await getPhoneBookCategories();
    if (response.status === 200) {
      for (let i = 0; i < response.data.data.length; i++) {
        response.data.data[i].numberOfEntries =
          response.data.data[i].attributes.phoneBookEntries.data.length;

        tmp.push(response.data.data[i]);
      }
    }

    try {
      await this.setStorage(JSON.stringify(tmp), "phoneBook");
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.categories = tmp;
    });
  }

  async getChildEntry(id: any) {
    let tmp: any;
    tmp = this.selectedPhoneBookEntry.attributes.phone_book_entries.data.find(
      (child: any) => child.id.toString() === id.toString()
    );
    runInAction(() => {
      this.childEntry = tmp;
    });

    let entry = this.childEntry.attributes;
    let image: any = [];
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.childImgURL = image;
    });
  }

  get filteredEntries() {
    if (!this.categories) {
      return [];
    }

    for (let category of this.categories) {
      const found: any = [];
      const phonebookEntries = category.attributes.phoneBookEntries;
      for (const phoneEntry of phonebookEntries.data) {
        if (
          (phoneEntry.attributes.location &&
            phoneEntry.attributes.location.toLowerCase() ===
            this.locationQuery) ||
          this.locationQuery === "all"
        ) {
          if (
            category.attributes.name === "Restaurants" ||
            category.attributes.name === "Bars"
          ) {
            found.push(phoneEntry);
          } else {
            if (!phoneEntry.attributes.parentId) {
              found.push(phoneEntry);
            }
          }
        }
      }
      category.numberOfEntries = found.length;
    }
    return this.categories;
  }

  //filter subgenres
  get filteredCategoryEntries() {
    const found = this.phoneBookEntriesForCategory.filter((entry: any) => {
      if (
        (entry.attributes &&
          entry.attributes.location &&
          entry.attributes.location.toLowerCase() === this.locationQuery) ||
        this.locationQuery === "all"
      ) {
        return true;
        // if (
        //   JSON.stringify(entry).toLowerCase().includes(this.query.toLowerCase())
        // ) {
        //   return true;
        // }
      }
      return false;
    });

    // sort by name
    return found.sort((a, b) => {
      const nameA = a.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      const nameB = b.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name must be equal
      return 0;
    });
  }

  setQ(q: string) {
    if (q !== " ") {
      runInAction(() => {
        this.query = q;
      });
    }
  }

  loadPhoneBookForCategory = (id: number) => {
    // const response = await getPhoneBookEntriesForCategory(id);
    // if (response.status === 200) {
    //   this.phoneBookEntriesForCategory = response.data.data;
    // }
    let tmp: any;
    tmp = this.categories.filter((category) => category.id === id);
    if (tmp && tmp[0]) {
      runInAction(() => {
        this.phoneBookEntriesForCategory =
          tmp[0].attributes.phoneBookEntries.data;
      });
    }
  };

  async getPhoneBookEntry(id: string) {
    // let tmp: any;
    // for (let category of this.categories) {
    //   tmp = category.attributes.phoneBookEntries.data.find((entry: any) => {
    //     return entry.id.toString() === id;
    //   });
    //   if (tmp) {
    //     tmp.attributes.phone_book_entries.data.sort((a: any, b: any) =>
    //       a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()
    //         ? 1
    //         : b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase()
    //         ? -1
    //         : 0
    //     );
    //     runInAction(() => {
    //       this.selectedPhoneBookEntry = tmp;
    //     });
    //   }
    // }

    const response = await getPhoneBookEntry(id);
    if (response.status === 200) {
      runInAction(() => {
        this.selectedPhoneBookEntry = response.data.data;
      });

      let entry = response?.data?.data?.attributes;
      let image: any = "";
      let month = new Date().getMonth() + 1;

      if (month >= 5 && month <= 10) {
        entry.imageSummer
          ? (image = await getImageForEntry(entry.imageSummer))
          : entry.imageWinter
            ? (image = await getImageForEntry(entry.imageWinter))
            : (image = "");
      } else {
        entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
      }

      runInAction(() => {
        this.imgURL = image;
      });
    }
  }

  async getImg(id: any) {
    const res = this.phoneBookEntries.find(
      (element: any) => element.id.toString() === id
    );

    const entry = res.attributes;
    let image: any = "";
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.imgURL = image;
    });
  }

  async getImgChild(parentId: any, childId: any) {
    const parent = this.phoneBookEntries.find(
      (element: any) => element.id.toString() === parentId
    );

    const child = parent?.attributes?.phone_book_entries?.data.find(
      (child: any) => child.id.toString() === childId.toString()
    );

    const entry = child?.attributes;
    let image: any = "";
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.childImgURL = image;
    });
  }

  getCategory(id: number) {
    // const response = await getPhoneBookCategory(id);
    // if (response.status === 200) {
    //   this.selectedCategory = response.data.data;
    // }
    this.selectedCategory = this.categories.find(
      (category) => category.id === id
    );
  }

  setLocationQuery(query: string) {
    runInAction(() => (this.locationQuery = query));
  }

  async createPhoneBookEntry(data: any) {
    const res = await createPhoneBookEntry(data);
  }
}

const phoneBookStore = new PhoneBookStore();

export default phoneBookStore;
