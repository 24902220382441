import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  RefresherEventDetail,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import CreatePhoneBookEntryModal from "../../components/CreatePhoneBookEntryModal/CreatePhoenBookEntryModal";
import PhoneBookChip from "../../components/PhoneBookChip/PhoneBookChip";
import SafeView from "../../components/SafeView/SafeView";
import Search from "../../components/Search/Search";
import Toolbar from "../../components/Toolbar/Toolbar";
import rules from "../../data/rules";
import phoneBookStore from "../../stores/phoneBook.store";
//@ts-ignore
import ReactMarkdown from "react-markdown";
// styles
import "./PhoneBookScreen.css";
import { useHistory } from "react-router";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import SearchScreen from "../SearchScreen/SearchScreen";
import PhoneBookEntry from "../../components/PhoneBookEntry/PhoneBookEntry";

const PhoneBookScreen: React.FC = () => {
  const [inputValue, setInputValue] = useState(phoneBookStore.query);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [topElements, setTopElements] = useState([]);
  const [importantOnes, setImportantOnes] = useState([]);
  const [voucher, setVoucher] = useState([]);

  useEffect(() => {
    phoneBookStore.loadCategories(true);
    phoneBookStore.loadPhoneBookEntries();
  }, []);

  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    phoneBookStore.loadCategories();
    phoneBookStore.loadPhoneBookEntries();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  useEffect(() => {
    const top: any = [];
    const important: any = [];
    const voucher: any = [];
    for (let entry of phoneBookStore.filteredEntries) {
      if (entry.attributes.name === "Gutschein-Betriebe") {
        voucher.push(entry);
      } else if (entry.attributes.isImportant) {
        important.push(entry);
      } else {
        top.push(entry);
      }
    }
    let lastElement = important[important.length - 1];
    important.unshift(lastElement);
    important.pop();

    setVoucher(voucher);
    setTopElements(top);
    setImportantOnes(important);
  }, [phoneBookStore.filteredEntries]);

  const history = useHistory();

  useEffect(() => {
    renderEntries();
  }, []);

  const renderEntries = () => {
    return (
      <div>
        {topElements.map((element: any) => {
          return (
            <div key={element.id}>
              <PhoneBookChip key={element.id} category={element} />
            </div>
          );
        })}
        {importantOnes.length > 0 && (
          <div className="phonebook-title">Wichtiges</div>
        )}
        {importantOnes.map((element: any) => {
          return (
            <div key={element.id}>
              <PhoneBookChip key={element.id} category={element} />
            </div>
          );
        })}
        <div className="phonebook-title">
          Diese Betriebe akzeptieren Gutscheine
        </div>
        {voucher.map((element: any) => {
          return (
            <div key={element.id}>
              <PhoneBookChip key={element.id} category={element} />
            </div>
          );
        })}
        {/* {phoneBookStore.filteredEntries.map((category: any, index: number) => {
          if (category.attributes.name === "Ärzte") {
            return (
              <div>
                <div className="phonebook-title">Wichtiges</div>
                <PhoneBookChip key={category.id} category={category} />
              </div>
            );
          }

          if (category.attributes.name === "Gutschein-Betriebe") {
            return (
              <div>
                <div className="phonebook-title">
                  Diese Betriebe akzeptieren Gutscheine
                </div>
                <PhoneBookChip key={category.id} category={category} />
              </div>
            );
          }
          return <PhoneBookChip key={category.id} category={category} />;
        })} */}
      </div>
    );
  };

  const renderSplitScreen = () => (
    <SplitScreen
      header={
        <>
          <Toolbar icon title="Telefonbuch" />

          <Search
            value={inputValue}
            onChange={(evt: any) => {
              const value = evt.target.value;
              setInputValue(value);
              if (timerRef.current) {
                clearTimeout(timerRef.current);
              }
              timerRef.current = setTimeout(() => {
                phoneBookStore.setQ(value);
              }, 500);
            }}
          />
          <IonSegment
            color={"#c8c8c8"}
            className="phonebook-segment"
            value={phoneBookStore.locationQuery}
            onIonChange={(e: any) => {
              if (e.detail.value === "all") {
                phoneBookStore.setLocationQuery("all");
              }

              phoneBookStore.setLocationQuery(
                e.detail.value ? e.detail.value : ""
              );
            }}
          >
            <IonSegmentButton value="all" className="phonebook-segment-button">
              <IonLabel>Alle</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="lech" className="phonebook-segment-button">
              <IonLabel>Lech</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="zürs" className="phonebook-segment-button">
              <IonLabel>Zürs</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="warth"
              className="phonebook-segment-button"
            >
              <IonLabel>Warth</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </>
      }
    >
      {phoneBookStore.query ? (
        <SearchScreen category={"all"} />
      ) : (
        <IonList>
          {renderEntries()}
          <div>
            <div className="phonebook-title">Infos</div>
          </div>
          <IonItem
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
            }}
            lines="full"
          >
            <IonLabel>Eintrag neu / ändern</IonLabel>
          </IonItem>
          <IonItem
            style={{ cursor: "pointer" }}
            lines="full"
            onClick={() => {
              history.push("/impressum");
            }}
          >
            <IonLabel>Impressum</IonLabel>
          </IonItem>
        </IonList>
      )}
      <IonModal
        style={{ height: "100%" }}
        //ref={modal}
        //trigger="open-create-modal"
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
        }}
      >
        <CreatePhoneBookEntryModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </IonModal>
    </SplitScreen>
  );

  return <SafeView extend={true}>{renderSplitScreen()}</SafeView>;
};

export default observer(PhoneBookScreen);
